import React from "react";

import { Button } from "../../atoms/Button";
import { Image } from "../../atoms/Image";
import { Label } from "../../atoms/Label";

import { Div } from "../../molecules/Div";

import imagelogo from "../../../images/login/logogreen.svg";
import imagegoogle from "../../../images/login/icons_google.png";
import { Span } from "../../atoms/Span";
import GoogleLogin from 'react-google-login';
import axios from "axios";
import moment from 'moment';
import { useDispatch } from "react-redux";
import { saveUser } from "../../../actions/auth";
import { useState } from "react";

const baseUrl = global.backenduser;

export const FormLogin = () => {
  const [error, setError] = useState({ classname: "main-login__no-error", text: "" })
  const dispatch = useDispatch();

  const responseGoogle = (response) => {
    getInfoUser(response.profileObj.email, response.profileObj.imageUrl);
  }

  const getInfoUser = async (email, img) => {
    let url = baseUrl+"/api/v1/user/findbyid/"+ email;
    const { data } = await axios.get(url, {
      headers: { Authorization: "Basic YWRtaW46YWRtaW4=" },
    });
    if (data === "") {
      loginDenied()
    } else {
      if (data.active) {
        getGroups(data, img)
      } else {
        loginDenied();
      }
    }
  };

  const getGroups = async (user, img) => {
    const { data } = await axios.get(`${baseUrl}/api/v1/group/findall`, {
      headers: { Authorization: "Basic YWRtaW46YWRtaW4=" },
    });
    getRoles(data, user, img)
  }

  const getRoles = async (groups, user, img) => {
    const { data } = await axios.get(`${baseUrl}/api/v1/rol/findall`, {
      headers: { Authorization: "Basic YWRtaW46YWRtaW4=" },
    });
    formatUser(groups, data, user, img)
  }

  const formatUser = (groups, roles, user, img) => {
    let group = "";
    let role = "";
    for (let i = 0; i < groups.length; i++) {
      if (groups[i].id === user.groupId) { group = groups[i].name }
    }
    for (let i = 0; i < roles.length; i++) {
      if (roles[i].id === user.rolId) { role = roles[i].name }
    }
    let credentials = {
      name: user.name, email: user.email, roleId: user.rolId, role: role,
      groupId: user.groupId, group: group, img: img
    }
    let sessionTimeout = moment().add(240, 'minutes');
    localStorage.setItem('pxsessionTimeout', sessionTimeout.format('DD/MM/YYYY, h:mm:ss a'));
    localStorage.setItem('pxuser', JSON.stringify(credentials));
    localStorage.setItem('pxlogged', true);
    dispatch(saveUser(credentials))
  }

  const loginFail = () => {
    setError({ classname: "main-login__error", text: "La contraseña o correo es incorrecto. Vuelve a intentarlo o solicita ayuda a tu lider de equipo." })
  }

  const loginDenied = () => {
    setError({ classname: "main-login__error", text: "Al parecer no tiendes permiso para acceder a Uranus. Vuelve a intentarlo o solicita ayuda a tu lider de equipo." })
  }


  return (
    <Div classname={"main-login"}>
      <Div classname={"main-login__logo"}>
        <Image source={imagelogo} />
      </Div>

      <Div classname={"main-login__google"}>
        <Label text={"Iniciar Sesión"} classname={"main-login__label"} />
        <GoogleLogin
          clientId="900188810836-squksnq1m965t3sfdimg7h1aspocvgdh.apps.googleusercontent.com"
          render={renderProps => (
            <Button classname={"main-login__button"} onClick={renderProps.onClick} disabled={renderProps.disabled}>
              <Image source={imagegoogle} />
              <Span classname={"main-login__span"}>Usar cuenta de google</Span>
            </Button>
          )}
          buttonText="Login"
          onSuccess={responseGoogle}
          onFailure={loginFail}
          cookiePolicy={'single_host_origin'}
        />
        <Div classname={error.classname}>
          {error.text}
        </Div>
      </Div>
    </Div>
  );
};
